import pxToRem from "hooks/usePxToRem";

import {Grid} from "@mui/material";

import {CustomAutoComplete, CustomInput, CustomInputWrapper} from "ui";
import {useState} from "react";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import {Controller, useFormContext} from "react-hook-form";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandlePaginatedOptions from "views/ProcessDetails/hooks/useHandlePaginatedOptions";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {CorrespondenceModal, HelperIcon} from "components/HelperIconAndModal";
import useValueOrEmpty from "hooks/useValueOrEmpty";
import ProcessDatePicker from "views/ProcessDetails/components/ProcessDatePicker";

const BasicInfo = () => {
  const {control} = useFormContext();

  const [clientOptions, setClientOptions] = useState<AutoCompleteData[]>(
    [] as AutoCompleteData[],
  );

  const {handleChangeAutocomplete} = useChangeDAteAndAutocomplete();

  const {getOptions, onBottomScroll, isLoadingOptions, pageState} =
    useHandlePaginatedOptions();

  const [searchFields, setSearchFields] = useState({
    client: "",
    vessels: "",
  });

  const [correspondenceModalIsOpen, setCorrespondenceModalIsOpen] =
    useState(false);
  const [correspondenceModalContent, setCorrespondenceModalContent] =
    useState("");
  const [correspondenceModalTitle, setCorrespondenceModalTitle] = useState("");

  const handleOpenHelperModal = (content: string, title: string) => {
    setCorrespondenceModalTitle(title);
    setCorrespondenceModalContent(content);
    setCorrespondenceModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setCorrespondenceModalIsOpen(false);
    setCorrespondenceModalContent("");
  };
  const {valueOrEmptyString} = useValueOrEmpty();
  return (
    <>
      <CorrespondenceModal
        isOpen={correspondenceModalIsOpen}
        correspondenceText={correspondenceModalContent}
        correspondenceTitle={correspondenceModalTitle}
        onClose={handleCloseModal}
      />
      <Grid container spacing={pxToRem(8)} mb={pxToRem(20)}>
        <CustomInputWrapper title="Número processo" xs={2} isDisabled>
          <Controller
            name="processNumber"
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value}
                placeholder="Número de processo"
                name="processNumber"
                disabled
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Data abertura" xs={2} isDisabled>
          <Controller
            name="dateProcessOpening"
            control={control}
            render={({field: {value}}) => (
              <ProcessDatePicker
                value={value}
                name="dateProcessOpening"
                disabled
              />
            )}
          />
        </CustomInputWrapper>
        <Controller
          name="client"
          control={control}
          render={({field: {value}}) => (
            <CustomInputWrapper
              title="Cliente"
              xs={3}
              sx={{position: "relative"}}
              helper={
                <HelperIcon
                  onClick={() =>
                    handleOpenHelperModal(
                      valueOrEmptyString(value.correspondence),
                      "Cliente",
                    )
                  }
                />
              }
            >
              <CustomAutoComplete
                size="small"
                name="client"
                placeholder="Cliente"
                tooltipText={getTooltipText(value?.name)}
                isLoading={isLoadingOptions}
                value={value?.name ?? ""}
                onChangeFn={(e, value) =>
                  handleChangeAutocomplete(e, value, "client")
                }
                options={clientOptions}
                onOpen={
                  clientOptions.length > 0
                    ? undefined
                    : () => getOptions({isClient: true}, setClientOptions)
                }
                onBottomScroll={() =>
                  onBottomScroll(
                    {isClient: true, clientName: searchFields.client},
                    setClientOptions,
                  )
                }
                hasMorePagesToLoad={pageState.currentPage < pageState.lastPage}
                onInputChange={(e, value) => {
                  //condição pra não fazer a requisição na primeira atualização do input
                  if (e !== null) {
                    setSearchFields((prev) => ({...prev, client: value}));
                    getOptions(
                      {isClient: true, clientName: value},
                      setClientOptions,
                    );
                  }
                }}
              />
            </CustomInputWrapper>
          )}
        />
        <CustomInputWrapper
          title="Referência cliente"
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Controller
            name="clientReference"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                value={value}
                name="clientReference"
                placeholder="Referência"
                onChange={onChange}
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
        <CustomInputWrapper title="Ref. externa" xs={2} isDisabled>
          <Controller
            name="externalReference"
            control={control}
            render={({field: {value}}) => (
              <CustomInput
                value={value}
                placeholder="Referência"
                name="externalReference"
                disabled
                size="small"
              />
            )}
          />
        </CustomInputWrapper>
      </Grid>
    </>
  );
};

export default BasicInfo;
