import {
  Typography,
  List,
  ListSubheader,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import {Stack, Box} from "@mui/system";
import pxToRem from "hooks/usePxToRem";
import React from "react";
import {CustomIconButton, CustomSelect, VerticalListSkeleton} from "ui";
import {ActivityListItem} from "./components";
import {format} from "date-fns";
import {ConfirmModal, ModalCompleteActivity} from "components";
import useActivitiesSidebar from "views/ProcessDetails/hooks/useActivitiesSidebar";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";

type Props = {
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ActivitiesSidebar = (props: Props) => {
  const theme = useTheme();
  const {setIsSidebarOpen, refetchProcess} = props;
  const {
    openCompleteActivityModal,
    setOpenCompleteActivityModal,
    currentProcessNumber,
    processAndGroupActivityId,
    taskData,
    statusToFilter,
    handleRestoreActivity,
    isLoadingActivities,
    getFilteredActivities,
    getSidebarStatusByActivityStatus,
    handleFinishActivity,
    changeStatusToFilter,
    handleUpdateRenderAfterProcessRefetch,
    openWarningDirtyFields,
    handleConfirmWarningDirtyFields,
    handleCloseWarningDirtyFields,
  } = useActivitiesSidebar({refetchProcess});

  const getFormattedDate = (date?: string) => {
    if (!date) return "-";

    return format(new Date(date), "dd/MM/yyyy");
  };

  return (
    <>
      {openWarningDirtyFields && (
        <ConfirmModal
          isOpen={openWarningDirtyFields}
          title={"Campos alterados sem salvar"}
          subtitle={
            <Typography>
              Há campos do processo que foram alterados mas não foram salvos.
              Deseja continuar com a conclusão das atividades e{" "}
              <Typography sx={{fontWeight: 600, display: "inline-block"}}>
                perder as alterações
              </Typography>{" "}
              ou cancelar para poder salvar?
            </Typography>
          }
          confirmButtonFn={handleConfirmWarningDirtyFields}
          onClose={handleCloseWarningDirtyFields}
          color="warning"
          confirmButtonIconIsDisabled
          confirmButtonText="Continuar"
          titleIcon="warning"
        />
      )}

      {openCompleteActivityModal && (
        <ModalCompleteActivity
          isOpen={openCompleteActivityModal}
          setIsOpen={setOpenCompleteActivityModal}
          processNumber={currentProcessNumber}
          processAndGroupActivityId={processAndGroupActivityId}
          firstTaskData={taskData}
          getActivityData={handleUpdateRenderAfterProcessRefetch}
          activitiesToCompleteInBatch={[]}
          setRowSelection={() => void 0}
          shouldGoToNextTask={false}
        />
      )}
      <Paper component={"aside"} elevation={0}>
        <Stack
          sx={{
            height: "calc(100vh - 8px)",
            width: `${pxToRem(330)}`,
            borderLeft: `${pxToRem(1)} solid ${theme.palette.divider}`,
            borderRadius: `${pxToRem(4)} 0 0 ${pxToRem(4)}`,

            display: "flex",
            flexDirection: "column",

            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Stack
            component="header"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: `${pxToRem(24)}`,
              borderBottom: `${pxToRem(1)} solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
              maxHeight: `${pxToRem(78)}`,
            }}
          >
            <Typography variant="h4" component="strong">
              Atividades do processo
            </Typography>

            <CustomIconButton
              iconName="close_rounded"
              tooltipText="Fechar barra lateral"
              iconSize={28}
              onClick={() => setIsSidebarOpen(false)}
              sx={(theme) => ({
                "&.MuiIconButton-root": {
                  padding: 0,
                  color: theme.palette.text.primary,
                },
              })}
              disableRipple
            />
          </Stack>
          <Box
            sx={{
              padding: `0 0 ${pxToRem(10)} ${pxToRem(20)}`,
              height: `calc(100vh - ${pxToRem(78)})`,
              overflow: "scroll",
            }}
          >
            <List
              dense
              component="nav"
              aria-labelledby="nested-list-subheader"
              sx={{padding: 0}}
            >
              {/* Componente comentado por ora, apenas */}
              {/* <ActivitiesToGenerateList /> */}
              <List
                component="div"
                disablePadding
                dense
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={(theme) => ({
                      paddingTop: pxToRem(20),
                      paddingLeft: 0,
                      backgroundColor: theme.palette.background.paper,
                      position: "sticky",
                      height: pxToRem(78),
                    })}
                  >
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        gap: pxToRem(50),
                      }}
                    >
                      <Grid item>Status</Grid>
                      <Grid
                        item
                        xs={6}
                        display="flex"
                        sx={{alignContent: "center"}}
                      >
                        <CustomSelect
                          fullWidth
                          size="small"
                          content={[
                            "Tudo",
                            "Concluída",
                            "Não iniciada",
                            "Outras",
                          ]}
                          value={statusToFilter}
                          onChange={changeStatusToFilter}
                          sx={{fontSize: pxToRem(12)}}
                        />
                      </Grid>
                    </Grid>
                  </ListSubheader>
                }
              >
                {isLoadingActivities && <VerticalListSkeleton />}
                {getFilteredActivities(statusToFilter).map((activity) => (
                  <ActivityListItem
                    key={activity.id}
                    name={activity.task.name}
                    status={getSidebarStatusByActivityStatus(
                      activity.activityStatus,
                    )}
                    handleConcludeActivity={() =>
                      handleFinishActivity(activity)
                    }
                    handleRestoreActivity={() =>
                      handleRestoreActivity(activity)
                    }
                    date={
                      activity.activityStatus === "Não iniciada"
                        ? getFormattedDate(activity?.estimatedFinish)
                        : getFormattedDate(activity?.updatedAt)
                    }
                  />
                ))}
                {getFilteredActivities(statusToFilter).length === 0 &&
                  !isLoadingActivities && (
                    <Typography textAlign="center" variant="body2">
                      Não há atividades para exibir
                    </Typography>
                  )}
              </List>
            </List>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default ActivitiesSidebar;
