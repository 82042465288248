import pxToRem from "hooks/usePxToRem";

import {
  Box,
  Chip,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import {CustomButton} from "ui";

import {useFormContext} from "react-hook-form";
import {
  ButtonWithDropdown,
  EmailPreviewModal,
  ModalHCNewActivity,
} from "components";
import useSendFollowUp from "hooks/useSendFollowUp";
import {useRef, useState} from "react";
import {EmailData} from "components/EmailPreviewModal/EmailPreviewModal";
import {useHistory, useParams} from "react-router-dom";
import useToast from "hooks/useToast";
import {NavLink, ModalGenerateSingleActivity} from "./components";

interface ProcessHeaderProps {
  isSidebarOpen: boolean;
}

const ProcessHeader = (props: ProcessHeaderProps) => {
  const theme = useTheme();
  const [showToast] = useToast();

  const {id: processId} = useParams<{id: string}>();
  const history = useHistory();
  const {watch, reset} = useFormContext();
  const cancelledProcess = watch("agencyingSituation") === "Cancelado";

  const {
    isOpen,
    emailReturn,
    handleGetFollowUp,
    handleSendFollowUp,
    emailData,
    setEmailData,
    handleCloseModal,
  } = useSendFollowUp();

  const [openModalHCNewActivity, setOpenModalHCNewActivity] = useState(false);
  const [
    modalGenerateActivityInAdvanceIsOpen,
    setModalGenerateActivityInAdvanceIsOpen,
  ] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleSendNewFollow = (
    formRef: React.RefObject<HTMLFormElement>,
    localEmailData: EmailData,
    editedEmail: string,
  ) => {
    if (formRef.current?.reportValidity()) {
      handleSendFollowUp(localEmailData, editedEmail);
    }
  };

  const handleCancelChanges = () => {
    reset(undefined, {keepDefaultValues: true});
    showToast("Alterações canceladas com sucesso!", "success");
  };

  const handleNavigateProcess = (idProcessToNavigate: number) => {
    if (!idProcessToNavigate) return;
    history.push(`/process-details/${idProcessToNavigate}`);
  };

  const handleOpenModalGenerateActivityInAdvance = () => {
    setModalGenerateActivityInAdvanceIsOpen(true);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={pxToRem(20)}
        paddingRight={props.isSidebarOpen ? pxToRem(12) : 0}
      >
        <Stack spacing={pxToRem(2)}>
          <Stack direction="row" alignItems="center" spacing={pxToRem(8)}>
            <Typography variant="h4" component="h2">
              Dados Gerais
            </Typography>
            <Chip
              label={`${watch("operation")[0]}${watch("modality")[0]}`}
              color="default"
            />
            <Box sx={{display: "flex", alignItems: "center"}}>
              <IconButton
                disabled={!watch("navigation.previousProcessId")}
                onClick={() =>
                  handleNavigateProcess(watch("navigation.previousProcessId"))
                }
              >
                <Tooltip title="Processo anterior" arrow placement="top">
                  <Icon>chevron_left</Icon>
                </Tooltip>
              </IconButton>
              <Tooltip
                title={cancelledProcess ? "Cancelado" : ""}
                arrow
                placement="top"
              >
                <Chip
                  label={watch("processNumber")}
                  color={cancelledProcess ? "error" : "primary"}
                />
              </Tooltip>
              <IconButton
                disabled={!watch("navigation.nextProcessId")}
                onClick={() =>
                  handleNavigateProcess(watch("navigation.nextProcessId"))
                }
              >
                <Tooltip title="Próximo processo" arrow placement="top">
                  <Icon>chevron_right</Icon>
                </Tooltip>
              </IconButton>
            </Box>
          </Stack>

          <Typography
            variant="body2"
            component="p"
            color="text.secondary"
            lineHeight={pxToRem(21)}
          >
            Visualize ou altere informações de um determinado processo.
          </Typography>
        </Stack>
        {isOpen && (
          <EmailPreviewModal
            processId={Number(processId)}
            isOpen={isOpen}
            handleClose={handleCloseModal}
            handleSendEmail={handleSendNewFollow}
            emailData={emailData}
            emailReturn={emailReturn}
            setEmailData={setEmailData}
            formRef={formRef}
          />
        )}
        {openModalHCNewActivity && (
          <ModalHCNewActivity
            processNumber={watch("processNumber")}
            isOpened={openModalHCNewActivity}
            setIsOpened={setOpenModalHCNewActivity}
            processIdGroupActivity={watch("idGroupActivity")}
          />
        )}

        {modalGenerateActivityInAdvanceIsOpen && (
          <ModalGenerateSingleActivity
            setModalGenerateSingleActivityIsOpen={
              setModalGenerateActivityInAdvanceIsOpen
            }
            modalGenerateSingleActivityIsOpen={
              modalGenerateActivityInAdvanceIsOpen
            }
          />
        )}

        <Stack
          direction="row"
          alignItems={"center"}
          spacing={pxToRem(12).toString()}
        >
          <ButtonWithDropdown
            variant="contained"
            lightBackground
            disabled={cancelledProcess}
            options={[
              {
                name: "Follow Up",
                actionFn: () => handleGetFollowUp(+processId),
              },
              {
                name: "Atividade HC",
                actionFn: () => setOpenModalHCNewActivity(true),
              },
              {
                name: "Atividade avulsa",
                actionFn: () => handleOpenModalGenerateActivityInAdvance(),
              },
            ]}
          >
            Novo
          </ButtonWithDropdown>
          <CustomButton
            size="medium"
            variant="outlined"
            onClickFn={handleCancelChanges}
            disabled={cancelledProcess}
          >
            Cancelar Alterações
          </CustomButton>
          <CustomButton
            size="medium"
            type="submit"
            endIcon="save"
            disabled={cancelledProcess}
          >
            Salvar
          </CustomButton>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={pxToRem(28)}
        sx={{
          borderBottom: `2px solid ${theme.palette.grey[100]}`,
          paddingBottom: pxToRem(8),
        }}
      >
        <NavLink name="processo" linkTo="process" />
        <NavLink name="embarque" linkTo="boarding" />
        <NavLink name="carga" linkTo="load" />
        <NavLink name="taxas" linkTo="taxes" />
        <NavLink name="Documentos" linkTo="documents" />
        <NavLink name="Acompanhamentos" linkTo="followUps" />
        <NavLink name="Liberação Online" linkTo="onlineRelease" />
      </Stack>
    </Box>
  );
};

export default ProcessHeader;
