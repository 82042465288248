import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  Typography,
} from "@mui/material";
import {CustomModal} from "components";
import {DefaultModalFooter} from "components/CustomModal/components";
import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {GetAllTasks} from "services/api_v2/ActivitiesHistory/activitiesHistory.service";
import {MakeSingleActivity} from "services/api_v2/CompleteActivity/CompleteActivity.service";
import {CustomButton} from "ui";

type Props = {
  setModalGenerateSingleActivityIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  modalGenerateSingleActivityIsOpen: boolean;
};

const ModalGenerateSingleActivity = (props: Props) => {
  const {
    setModalGenerateSingleActivityIsOpen:
      setModalGenerateActivityInAdvanceIsOpen,
    modalGenerateSingleActivityIsOpen: modalGenerateActivityInAdvanceIsOpen,
  } = props;

  const {watch, getValues} = useFormContext();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();

  const handleCloseModalGenerateActivityInAdvance = () => {
    setModalGenerateActivityInAdvanceIsOpen(false);
    setValue(null);
  };

  const [value, setValue] = useState<string | null>(null);
  const handleChangeSelectedActivity = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue((e.target as HTMLInputElement).value);
  };

  const [availableTasks, setAvailableTasks] = useState<
    {id: number; name: string}[]
  >([]);

  const postData = async (processId: number, taskId: number) => {
    try {
      setLoading(true);
      await MakeSingleActivity(processId, taskId);
      handleCloseModalGenerateActivityInAdvance();
      showToast("Atividade criada com sucesso!", "success");
    } catch (error: any) {
      console.error(error);
      showAlert(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateActivity = (processId: number, taskId: string | null) => {
    if (!taskId) {
      showToast(
        "É necessário selecionar uma Atividade para a criação!",
        "error",
      );
      return;
    }
    postData(processId, +taskId);
  };

  const [showSkeleton, setShowSkeleton] = useState(true);

  const getAvailableTasks = async () => {
    const isGeneratedSeparately = true;
    try {
      const response = await GetAllTasks<{id: number; name: string}[]>(
        isGeneratedSeparately,
        getValues("idTaskGroup"),
      );
      const availableTasks = response.data?.map((task) => ({
        id: task?.id,
        name: task?.name,
      }));
      setAvailableTasks(availableTasks);
    } catch (error) {
      console.error(error);
    } finally {
      setShowSkeleton(false);
    }
  };

  useEffect(() => {
    getAvailableTasks();
  }, []);

  return (
    <CustomModal
      dividerBadge={watch("processNumber")}
      maxWidth="sm"
      isOpen={modalGenerateActivityInAdvanceIsOpen}
      onClose={handleCloseModalGenerateActivityInAdvance}
      title="Gerar atividade avulsa"
      subtitle="A atividade selecionada será criada imediatamente."
      titleIcon="bolt"
      dataTestId="modal-generate-activity-in-advance"
    >
      <Grid container p={pxToRem(20)}>
        <FormControl sx={{width: "100%"}}>
          <FormLabel id="activity-label">Atividade</FormLabel>
          <RadioGroup
            aria-labelledby="radio-group-activities"
            name="radio-group-activities"
            value={value}
            onChange={handleChangeSelectedActivity}
          >
            <Grid
              container
              sx={{
                maxHeight: pxToRem(200),
                overflowY: "auto",
              }}
            >
              {showSkeleton
                ? [1, 2, 3, 4, 5].map((task) => (
                    <Grid item xs={12} key={task}>
                      <Typography variant="h3">{<Skeleton />}</Typography>
                    </Grid>
                  ))
                : availableTasks.map((task) => (
                    <Grid item xs={12} key={task.id}>
                      <FormControlLabel
                        value={task.id}
                        key={task.id}
                        control={<Radio />}
                        label={task.name}
                      />
                    </Grid>
                  ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <DefaultModalFooter>
        <Grid
          container
          spacing={pxToRem(8)}
          sx={{display: "flex", justifyContent: "flex-end"}}
        >
          <Grid item>
            <CustomButton
              variant="outlined"
              onClickFn={handleCloseModalGenerateActivityInAdvance}
            >
              Cancelar
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              disabled={!value}
              onClickFn={() => handleGenerateActivity(getValues("id"), value)}
            >
              Confirmar
            </CustomButton>
          </Grid>
        </Grid>
      </DefaultModalFooter>
    </CustomModal>
  );
};

export default ModalGenerateSingleActivity;
