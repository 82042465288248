import useAlertMessage from "hooks/useAlertMessage";
import {Dispatch, SetStateAction, useState} from "react";
import {
  GetFilteredLegalPersons,
  QueryFilterPaginatePerson,
} from "services/api_v2/LegaPersons/LegalPersons.service";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

const useHandlePaginatedOptions = () => {
  const [showAlert] = useAlertMessage();

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [pageState, setPageState] = useState<{
    currentPage: number;
    lastPage: number;
  }>(
    {} as {
      currentPage: number;
      lastPage: number;
    },
  );

  const getOptions = async (
    param: QueryFilterPaginatePerson,
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
    page?: number,
  ) => {
    try {
      setIsLoadingOptions(true);
      const {data} = await GetFilteredLegalPersons({
        ...param,
        page: page,
      });

      const newOptions = data.data?.map((client) => ({
        label: `${client?.name} - ${client?.id}`,
        id: client.id,
      }));

      setPageState({
        lastPage: data.meta?.lastPage,
        currentPage: data.meta?.currentPage,
      });

      if (page && page > 1) {
        setStateFn((state) => [...state, ...newOptions]);
      } else {
        setStateFn(newOptions);
      }
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
      console.error(error);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  const onBottomScroll = (
    param: QueryFilterPaginatePerson,
    setStateFn: Dispatch<SetStateAction<AutoCompleteData[]>>,
  ) => {
    if (pageState.currentPage < pageState.lastPage) {
      getOptions(param, setStateFn, pageState.currentPage + 1);
      return true;
    }
  };

  return {getOptions, onBottomScroll, isLoadingOptions, pageState};
};

export default useHandlePaginatedOptions;
