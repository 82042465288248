/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import React, {SyntheticEvent} from "react";
import {ExpandMore} from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import {RenderGroupedList} from "ui";
interface SearchSelectProps {
  options: {
    label: string;
    id: string | number;
    additional?: string | number | {cnpj?: string; vatNumber?: string};
  }[];
  inputLabel?: string;
  value?: any;
  inputValue?: string;
  fnOnInputChange?: (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => void;
  fnOnChange?: (event: SyntheticEvent<Element, Event>, newValue: any) => void;
  fnOnOpen?: () => void;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
  required?: boolean;
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: number;
  popupIcon?: Node;
  name?: string;
  placeholder?: string;
  fullWidth?: boolean;
  multiple?: boolean;
  onBottomScroll?: () => boolean | void;
  groupBy?: (options: any) => string;
  permanentPlaceholder?: boolean;
  sizeOfPlaceholder?: number;
  loading?: boolean;
  hasMorePagesToLoad?: boolean;
  freeSolo?: boolean;
  limitTags?: number;
  paddingTopBottom?: boolean;
  placeholderFontWeight?: number;
  placeholderFontSize?: string;
  optionSecondaryContent?: boolean;
}

const CustomSearchSelect = (props: SearchSelectProps) => {
  const theme = useTheme();
  const {
    freeSolo = true,
    options,
    inputLabel,
    value,
    fnOnInputChange,
    fnOnChange,
    sx,
    size,
    inputValue,
    disabled,
    required,
    fontSize,
    popupIcon,
    name,
    placeholder,
    fullWidth,
    multiple,
    fnOnOpen,
    onBottomScroll = () => false,
    groupBy,
    permanentPlaceholder,
    sizeOfPlaceholder = 2,
    fontWeight,
    loading,
    hasMorePagesToLoad,
    limitTags,
    paddingTopBottom,
    optionSecondaryContent,
    ...rest
  } = props;

  const sizeOfPermanentPlaceholder =
    sizeOfPlaceholder > 2 ? pxToRem(12 * sizeOfPlaceholder) : pxToRem(32);

  const ListboxComponent = React.forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    return (
      <ul
        {...props}
        ref={ref}
        id="scrollableUl"
        style={{
          minHeight: pxToRem(200),
          overflow: hasMorePagesToLoad ? "scroll" : "auto",
        }}
      >
        {hasMorePagesToLoad ? (
          <InfiniteScroll
            dataLength={options?.length}
            next={onBottomScroll}
            hasMore={!!hasMorePagesToLoad}
            loader={<CircularProgress />}
            scrollableTarget="scrollableUl"
            style={{overflow: hasMorePagesToLoad ? "scroll" : "auto"}}
          >
            {props.children}
          </InfiniteScroll>
        ) : (
          props.children
        )}
      </ul>
    );
  });

  const optionSecondaryText = (option) => {
    if (typeof option?.additional === "string") {
      return option?.additional;
    }
    if (option?.additional?.cnpj) {
      return `CNPJ: ${option?.additional?.cnpj}`;
    } else if (option?.additional?.vatNumber) {
      return `VAT Number: ${option?.additional?.vatNumber}`;
    }
    return "-";
  };

  return (
    <FormControl fullWidth={fullWidth} sx={{...sx}}>
      <Autocomplete
        ListboxComponent={ListboxComponent}
        freeSolo={freeSolo}
        multiple={multiple}
        options={options}
        limitTags={limitTags ?? 3}
        onOpen={fnOnOpen}
        loading={loading}
        sx={{
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingLeft: permanentPlaceholder
              ? sizeOfPermanentPlaceholder
              : pxToRem(14 - 8),
            paddingTop: paddingTopBottom ? pxToRem(7.5) : undefined,
            paddingBottom: paddingTopBottom ? pxToRem(7.5) : undefined,
          },
          ".MuiOutlinedInput-root.MuiInputBase-sizeMedium": {
            paddingLeft: permanentPlaceholder
              ? sizeOfPermanentPlaceholder
              : pxToRem(14 - 8),
          },
          ".MuiAutocomplete-tag": {
            margin: 0,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            name={name}
            label={inputLabel}
            required={required}
            InputProps={{...params.InputProps}}
            placeholder={placeholder ?? ""}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: fontSize ?? pxToRem(14),
                fontWeight: fontWeight ?? 400,
                "&::placeholder": {
                  fontWeight: rest.placeholderFontWeight ?? 400,
                  opacity: 0.5,
                },
              },
            }}
          />
        )}
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              key={`${option?.id}.${state?.index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography style={{fontSize: fontSize ?? pxToRem(16)}}>
                {option.label}
              </Typography>
              {optionSecondaryContent && (
                <Typography style={{fontSize: pxToRem(12)}}>
                  {optionSecondaryText(option)}
                </Typography>
              )}
            </li>
          );
        }}
        value={value}
        inputValue={inputValue}
        onInputChange={fnOnInputChange}
        onChange={fnOnChange}
        size={size ?? "medium"}
        clearOnBlur
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        disabled={disabled}
        popupIcon={
          popupIcon ?? <ExpandMore sx={{color: theme.palette.grey[600]}} />
        }
        groupBy={groupBy}
        renderGroup={(params) => <RenderGroupedList params={params} />}
        getOptionLabel={(option) => {
          return typeof option === "string" ? option : option?.label;
        }}
      />
    </FormControl>
  );
};

export default CustomSearchSelect;
